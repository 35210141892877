<!-- 提车礼品报缺 -->
<template>
  <div :class="['giftDeletion']">
    <List ref="list" :fetchApi="fetchApi">
      <template v-slot:default="{ item, index }">
        <div class="deletion">
          <div class="item">
            <span class="label">{{ $t('申请时间：') }}</span>
            {{ item.createTime }}
          </div>
          <div class="item">
            <span class="label">{{ $t('申请人：') }}</span>
            {{ item.applyName }}
          </div>
          <div class="item">
            <span class="label">{{ $t('状态：') }}</span>
            {{ item.status == 1 ? $t('未处理') : $t('已处理') }}
            <span class="detail" @click="goDetail(item)">{{ $t('查看详情') }}</span>
          </div>
        </div>
      </template>
    </List>
    <div v-btn-premission="'BTN-SUBMIT'" class="common-footer">
      <van-button class="btn submit" type="submit" @click="goAdd">{{$t('新建申请')}}</van-button>
    </div>
  </div>
</template>
<script>
import List from '@/components/baseList/list.vue'
import giftDeletionServices from '@/services/giftDeletionServices'
import loading from '@/utils/loading'
export default {
  name: 'GiftDeletionList',
  components: {
    List
  },
  data () {
    return {
      
    }
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    fetchApi() {
      const params = {}
      return giftDeletionServices.giftDeletionList(params).then((res) => {
        loading.hideLoading()
        return res
      })
    },
    // 详情
    goDetail({ id }) {
      this.$router.push({
        path: '/gift-deletion-add',
        query: {
          operate: 'detail',
          id
        }
      })
    },
    // 新建
    goAdd() {
      this.$router.push({
        path: '/gift-deletion-add',
        query: {
          operate: 'add'
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.giftDeletion{
  padding-bottom: 96px;
  .deletion{
    background: #fff;
    box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
    border-radius: 8px;
    padding: 16px 12px;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    color: @black;
    .item{
      &:not(:last-child){
        margin-bottom: 8px;
      }
      .label{
        color: rgba(13, 23, 26, 0.45)
      }
      .detail{
        color: @yellow-text;
        float: right
      }
    }
  }
}
</style>