import request from '@/utils/request'
import SERVER from './server'
import { Toast } from 'vant'
import { thenResolve } from '@/utils/index'

const giftDeletionServices = {
  /**
   * 提交报缺申请
   */
  async giftDeletionApplay(params) {
    return await request.post(`${SERVER.Test_Drive}/client/v1/present/lack/apply`, params).then(thenResolve)
  },
  /**
   * 报缺申请列表
   *  */ 
  async giftDeletionList(params) {
    return await request.post(`${SERVER.Test_Drive}/client/v1/present/apply/page`, params).then(thenResolve)
  },
  /**
   * 查看报缺详情
   */
  async giftDeletionDetail(params) {
    return await request.get(`${SERVER.Test_Drive}/client/v1/present/apply/detail`, { params }).then(thenResolve)
  },
}
export default giftDeletionServices
